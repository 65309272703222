<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="选择地址"
      left-text="返回"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-search
      v-model="state.keyword"
      placeholder="请输入关键词"
      clear-trigger="always"
      @search="onSearch"
      @cancel="onCancel"
      @clear="onCancel"
    />
    <div id="map-container" style="width: 100%; height: 320px"></div>
    <van-field
      v-model="state.area"
      input-align="center"
      placeholder="省市区"
      label=""
      readonly
    />
    <van-field
      v-model="state.address"
      input-align="center"
      :disabled="state.area == '省市区'"
      placeholder="请点击地图选择地址"
      label=""
    />
    <div class="py-4 action-button-area text-center">
      <van-button
        type="primary"
        :disabled="state.area == '省市区' || !state.address"
        @click="confirmAddress"
        >选择此地址</van-button
      >
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { Field, Search } from "vant";
import { useRouter } from "vue-router";
import { onMounted, onUnmounted, reactive } from "vue";
import { loadAddressFromSession } from "@/api/map.service";
export default {
  name: "AddressPicker",
  components: {
    [Field.name]: Field,
    [Search.name]: Search,
  },

  setup() {
    const router = useRouter();
    const state = reactive({
      map: null,
      maker: null,
      geocoder: null,
      placeSearch: null,
      lat: null,
      lng: null,
      area: "省市区",
      address: "请点击地图选择地址",
    });

    const confirmAddress = () => {
      const data = {
        isNewChoice: true,
        area: state.area,
        address: state.address,
        lat: state.lat,
        lng: state.lng,
      };
      window.sessionStorage.setItem("chooseAddress", JSON.stringify(data));
      router.back();
    };

    const initMap = async () => {
      try {
        const AMap = await AMapLoader.load({
          key: "80f6edbaf2b0446d6502b41d486f1388", // 首次加载必传鉴权
          version: "2.0",
          plugins: [
            "AMap.moveAnimation",
            "AMap.Geocoder",
            "AMap.ToolBar",
            "AMap.PlaceSearch",
          ],
        });

        // 初始化地图
        state.map = new AMap.Map("map-container", {
          scrollWhee: true,
          resizeEnable: true,
          viewMode: "2D",
          zoom: 15,
          // mapStyle: "amap://styles/fresh",
        });

        // 构造地点查询类
        state.placeSearch = new AMap.PlaceSearch({
          map: state.map,
          autoFitView: true,
          pageSize: 5,
          pageIndex: 1,
        });

        state.geocoder = new AMap.Geocoder();
        state.marker = new AMap.Marker();
        state.map.add(state.marker);

        // 默认标记
        if (state.lat && state.lng) {
          const centerPosition = new AMap.LngLat(state.lng, state.lat);
          state.marker.setPosition(centerPosition);
          state.map.setCenter(centerPosition);
          state.map.setFitView();
        }

        // 地图点击事件
        state.map.on("click", (e) => {
          state.marker.setPosition(e.lnglat);
          state.lat = e.lnglat.getLat();
          state.lng = e.lnglat.getLng();
          state.map.setCenter(e.lnglat);
          state.map.setFitView();
          state.geocoder.getAddress(e.lnglat, (status, result) => {
            if (status === "complete" && result.regeocode) {
              const {
                province,
                city,
                district,
              } = result.regeocode.addressComponent;
              state.area = `${province}${city}${district}`;
              state.address = result.regeocode.formattedAddress.replace(
                state.area,
                ""
              );
            }
          });
        });
      } catch (error) {
        console.log(error);
      }
    };

    const onSearch = (keyword) => {
      state.placeSearch.search(keyword);
    };

    const onCancel = () => {
      state.placeSearch.clear();
    };

    onMounted(() => {
      // 尝试取出之前已选择的地址
      const data = loadAddressFromSession();
      if (data) {
        state.area = data.area;
        state.address = data.address;
        state.lng = data.lng;
        state.lat = data.lat;
      }
      initMap();
    });

    onUnmounted(() => {
      try {
        this.map.destroy();
      } catch (err) {
        // ...
      }
    });

    return { state, onSearch, onCancel, confirmAddress };
  },
};
</script>
