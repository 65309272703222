// 返回用户选择的地址信息, 如果是新选择的地址, 立马将其状态更新为旧的
// 只要通过此函数获取了一次地址信息, local session里的地址状态就不是新的了
// 更多详见 @/views/AddressPicker.vue
// @todo 如果后期有需要可以考虑改造成 store 储存状态, 不推荐!
export function loadAddressFromSession() {
  try {
    const data = JSON.parse(window.sessionStorage.getItem("chooseAddress"));
    const isNewChoice = data && data.isNewChoice;
    if (isNewChoice) {
      data.isNewChoice = false;
      window.sessionStorage.setItem("chooseAddress", JSON.stringify(data));
    }
    return { ...data, isNewChoice };
  } catch (error) {
    console.log(error);
    return null;
  }
}
